import { LiveConst } from "../constants/live";
import { setAlertsEsito } from "./alert";
import { checkEventoFiltri, confrontoAbilitato, isConcessionarioVisible, isQuotaVisible, isConcessionarioConfronto } from "./filtri";
import suono_alert from '../assets/avviso.mp3';
import { alertBarInfoEnum } from '../redux/live/constants'

/**
 * Permette di aggiungere delle chiavi utili per la visualizzazione degli eventi
 * @param {dict} eventi dizionario degli eventi
 * @returns {dict} dizionario degli eventi modificato con le chiavi aggiunte
 */
export const addKeyToEventi = (eventi) => {
    let tempEventi = {};
    Object.keys(eventi).forEach(pevento_id => {
        tempEventi[pevento_id] = addKeyToEvento(eventi[pevento_id]);
    });
    return tempEventi;
}

/**
 * Permette di aggiungere delle chiavi utili per la visualizzazione dell'evento
 * @param {dict} evento dizionario dell'evento 
 * @returns {dict} evento modificato con le chiavi aggiunte
 */
export const addKeyToEvento = (evento) => {
    return { ...evento, visible: true, data_attivazione_alert: null, count_quota_alert: 0 };
}

/**
 * Funzione che permette di generare la lista di campionati presenti nell'applicazione
 * @param {dizionario} eventi dizionario contenente tutti gli eventi indicizzati per pevento_id 
 * @returns {dizionario} dizionario indicizzato per pcampionato_id
 */
export const generateListaCampionati = (eventi) => {
    let listaCampionati = {};
    Object.keys(eventi).forEach(pevento_id => {
        let pcampionato_id = eventi[pevento_id].pcampionato_id;
        let campionato_descrizione = eventi[pevento_id].descrizione;
        if (!(pcampionato_id in listaCampionati)) {
            listaCampionati[pcampionato_id] = campionato_descrizione;
        }
    });
    return listaCampionati;
}

/**
 * Dato l'alert genera un id univoco dai dati forniti
 * @param {*} alertInfo 
 * @returns string ID univoco dell'alert
 */
function generateIDAlert(alertInfo) {
    var id = `${alertInfo['pevento_id']}-${alertInfo['alert_id']}`;
    alertInfo['quote'].forEach((quota) => {
        var lid = `${quota.concessionario_id}#${quota.pquota_id}`;
        id += `-${lid}`
    });
    return id;
}

/**
 * Compara due dizionari controllando le differenze basandosi su concessionario_id e id alert
 * @param {*} objOne 
 * @param {*} ObjTwo 
 * @returns Array di elementi differenti
 */
function compareObjects(objOne, ObjTwo, element) {
    let objReturn = []

    for (const concessionari_id in objOne) {
        let arrAlertOne = [];
        if (objOne[concessionari_id] != false && objOne[concessionari_id] != undefined) {
            arrAlertOne = objOne[concessionari_id];
        }

        let arrAlertTwo = [];
        if (ObjTwo[concessionari_id] != false && ObjTwo[concessionari_id] != undefined) {
            arrAlertTwo = ObjTwo[concessionari_id];
        }


        // aggiungo a objReturn tutti gli elementi di arrAlertOne non presenti in arrAlertTwo(controllare l'alert id e concessinario id)
        // objReturn ha come chiave "concessionari_id"

        if (!Array.isArray(arrAlertOne)) {
            arrAlertOne = Array.from([arrAlertOne])
        }

        if (!Array.isArray(arrAlertTwo)) {
            arrAlertTwo = Array.from([arrAlertTwo])
        }

        for (const arrElementOne of arrAlertOne) {
            let arrContain = false;
            if (ObjTwo != false) {
                for (const arrElementTwo of arrAlertTwo) {
                    if (arrElementOne.concessionario_id == arrElementTwo.concessionario_id
                        && arrElementOne.id == arrElementTwo.id) {
                        arrContain = true;
                        break;
                    }
                }
            }

            if (!arrContain) {
                objReturn.push({ 'concessionarioAlert_id': concessionari_id, info: arrElementOne });
            }
        }
    }

    return objReturn;
}

/**
 * Calcolo del guadagno tra banca e punta
 * @param {*} banca 
 * @param {*} punta 
 * @returns Percentuale di guadagno
 */
export function calcoloGuadagno(banca, punta) {
    if (punta > banca) {
        return ((punta - banca) / banca) * 100;
    }
    return 0;
}

/**
 * Gestione del sorting degli alert
 * Si basa sullo stato globale sortAlertBarInfo contenete le informazioni per il sorting
 * @param {*} tempAlertsBar stato temporaneo degli alert
 * @param {*} state stato dall'applicazione
 * @returns Stato temporaneo degli alert ordinato
 */
export function sortingAlertBar(tempAlertsBar, state) {

    if (state.sortAlertBarInfo.sorting == alertBarInfoEnum.PERCENTUALE_GUADAGNO) {
        if (state.sortAlertBarInfo.order == alertBarInfoEnum.CRESCENTE) {
            tempAlertsBar = tempAlertsBar.sort(function (a, b) { return a.alert.percentuale_guadagno - b.alert.percentuale_guadagno })
        } else if (state.sortAlertBarInfo.order == alertBarInfoEnum.DECRESCENTE) {
            tempAlertsBar = tempAlertsBar.sort(function (a, b) { return b.alert.percentuale_guadagno - a.alert.percentuale_guadagno })
        }
    } else if (state.sortAlertBarInfo.sorting == alertBarInfoEnum.TIMESTAMP) {
        if (state.sortAlertBarInfo.order == alertBarInfoEnum.CRESCENTE) {
            tempAlertsBar = tempAlertsBar.sort(function (a, b) {
                return b.alert.timestamp - a.alert.timestamp;
            })
        } else if (state.sortAlertBarInfo.order == alertBarInfoEnum.DECRESCENTE) {
            tempAlertsBar = tempAlertsBar.sort(function (a, b) {
                return a.alert.timestamp - b.alert.timestamp;
            })
        }
    }
    return tempAlertsBar;
}

/**
 * Permette di aggiornare direttamente lo stato con le quote aggiornate e valutando eventuali alert attivi e la visibilità degli eventi 
 * Gestisce l'aggiunta, modifica e rimozione degli alert 
 * @param {array} elencoQuote contiene l'elenco delle quote da aggiungere/aggiornare 
 * @param {dizionario} state stato dell'applicazione 
 * @returns {dizionario} stato aggiornato
 */
export const setQuoteAggiornate = (elencoQuote, state) => {
    let tempAlertsBar = [...state.alertsBar]
    let tempQuote = { ...state.quote };
    let tempEventi = { ...state.eventi };
    let tempState = { ...state };
    elencoQuote.forEach(element => {
        // verifico se l'evento è un evento per cui bisogna invertire le quote in caso positivo inverto le quote
        let key_associazione = String(element.evento_codice) + '-' + String(element.concessionario_id);
        if (key_associazione in state.elencoAssociazioniEventi && parseInt(state.elencoAssociazioniEventi[key_associazione]['inverti']) === 1) {
            // verifico se la quota_id è presente nel dizionario delle quote
            if (element.pquota_id in state.inversioni_quote && state.inversioni_quote[element.pquota_id] !== null) {
                element.pquota_id = state.inversioni_quote[element.pquota_id];
            }
        }
        if (!(element.pevento_id in tempQuote)) {
            tempQuote[element.pevento_id] = {};
        }
        if (!(element.pquota_id in tempQuote[element.pevento_id])) {
            tempQuote[element.pevento_id][element.pquota_id] = {};
        }
        if (!(element.concessionario_id in tempQuote[element.pevento_id][element.pquota_id])) {
            tempQuote[element.pevento_id][element.pquota_id][element.concessionario_id] = {};
        }
        tempQuote[element.pevento_id][element.pquota_id][element.concessionario_id]['value'] = parseFloat(element.value);
        // prendo i vecchi alert
        let oldAlerts = getAlerts(state, element, tempQuote);
        let evento = null;
        if (element.pevento_id in tempEventi) {
            evento = tempEventi[element.pevento_id];
        }
        setAlertsEsito(tempQuote[element.pevento_id][element.pquota_id], tempState.alertConfronti, evento,);
        // verifico se ci sono nuovi alert
        let newAlerts = getAlerts(state, element, tempQuote);

        // recupero le differenze tra vecchio e nuovo
        let comparaAlertOld = compareObjects(oldAlerts, newAlerts, element); // rimuovo i vecchi che non sono presenti nei nuovi

        for (const concAlertOld of comparaAlertOld) {
            // rimuovo l'alert se combacia l'evento, l'alert, il concessionario e la quota
            for (let index = tempAlertsBar.length - 1; index >= 0; index--) {
                let indexRemove = -1;
                if (tempAlertsBar[index].alert.pevento_id == element.pevento_id && tempAlertsBar[index].alert.alert_id == concAlertOld.info.id) {
                    for (const quota of tempAlertsBar[index].alert.quote) {
                        if (quota.concessionario_id == concAlertOld.concessionarioAlert_id && quota.pquota_id == element.pquota_id) {
                            indexRemove = index;
                            break;
                        }
                    }

                    if (indexRemove != -1) {
                        tempAlertsBar.splice(indexRemove, 1);
                    }
                }
            }
        }

        // recupero la differenza tra nuovo e vecchio
        let comparaAlertNew = compareObjects(newAlerts, oldAlerts, element); // aggiuno i nuovi non presenti nei vecchi

        if (comparaAlertNew.length > 0) {
            // aggiungo l'alert per ogni concessionario di confronto possibile
            for (const concAlertNew of comparaAlertNew) {
                if (isConcessionarioVisible(concAlertNew.concessionarioAlert_id, state.concessionari)
                    && isQuotaVisible(element.pquota_id, state.specialita)
                    && element.pevento_id in tempEventi
                    && !state.campionati_non_visualizzati.includes(tempEventi[element.pevento_id].pcampionato_id)) {
                    for (const alertConfronti of state.alertConfronti) {
                        let alertConfrontiQuote = tempQuote[element.pevento_id][element.pquota_id][alertConfronti.concessionario_id];

                        if (alertConfrontiQuote != undefined && alertConfronti.abilitato) {
                            let alertInfo = {
                                'percentuale_guadagno': '',
                                'pevento_id': element.pevento_id,
                                'alert_id': concAlertNew.info.id,
                                'timestamp': Date.now(),
                                'quote': []
                            };

                            let concessionarioRiferimento = {
                                'concessionario_id': alertConfronti.concessionario_id,
                                'pquota_id': element.pquota_id,
                                'quota_iniziale': alertConfrontiQuote['value']
                            }

                            alertInfo.quote.push(concessionarioRiferimento);

                            let concesisonarioConfronto = {
                                'concessionario_id': concAlertNew.concessionarioAlert_id,
                                'pquota_id': element.pquota_id,
                                'quota_iniziale': tempQuote[element.pevento_id][element.pquota_id][concAlertNew.concessionarioAlert_id]['value']
                            }

                            alertInfo.quote.push(concesisonarioConfronto);

                            alertInfo.percentuale_guadagno = calcoloGuadagno(alertInfo.quote[0].quota_iniziale, alertInfo.quote[1].quota_iniziale);
                            tempAlertsBar.push({ id: generateIDAlert(alertInfo), alert: alertInfo });
                        }
                    }
                }
            }
            tempAlertsBar = sortingAlertBar(tempAlertsBar, state)
        }

        // gestione della modifica degli alert
        if (comparaAlertNew.length == 0 && comparaAlertOld.length == 0 && tempAlertsBar.length > 0) {
            for (const alertInfo of tempAlertsBar) {
                let pvento_id = alertInfo.alert.pevento_id;
                let quota_id = alertInfo.alert.quote[0].pquota_id;
                let concessionario_idOne = alertInfo.alert.quote[0].concessionario_id;
                let concessionario_idTwo = alertInfo.alert.quote[1].concessionario_id;

                alertInfo.alert.percentuale_guadagno = calcoloGuadagno(tempQuote[pvento_id][quota_id][concessionario_idOne]['value'], tempQuote[pvento_id][quota_id][concessionario_idTwo]['value']);
            }
            tempAlertsBar = sortingAlertBar(tempAlertsBar, state)
        }

        // verifico prima i filtri così farò suonare l'avviso solo se l'evento poi risulta visibile ed è almeno presente
        // verifico i filtri
        tempState = { ...tempState, quote: tempQuote };
        if (element.pevento_id in tempEventi) {
            tempEventi[element.pevento_id].visible = checkEventoFiltri(element.pevento_id, tempState);
        }
        // devo far suonare l'avviso se
        // il suono di alert è abilitato, se l'aggiornamento è rispetto ad un concessionario visibile, se è rispetto ad una specialita visibile
        if (state.alert_sound
            && isConcessionarioVisible(element.concessionario_id, state.concessionari)
            && isQuotaVisible(element.pquota_id, state.specialita)
            && element.pevento_id in tempEventi
            && tempEventi[element.pevento_id].visible
        ) {
            // verifico se l'aggiornamento è relativo ad un concessionario di confronto
            if (!isConcessionarioConfronto(element.concessionario_id, state.alertConfronti)) {
                compareAlerts(comparaAlertOld, comparaAlertNew, state);
            } else {
                // devo recuperare tutti gli altri concessionari relativi a questo evento e verificare se ci sono novi alert
                let ring = false;
                Object.keys(tempQuote[element.pevento_id][element.pquota_id]).forEach(concessionario_id => {
                    if (concessionario_id !== element.concessionario_id) {

                        let oldAlert = comparaAlertOld.find((concessionario) => (concessionario.concessionarioAlert_id == concessionario_id));
                        if (oldAlert != undefined) {
                            oldAlert = Array.from([oldAlert])
                        }

                        let newAlert = comparaAlertNew.find((concessionario) => (concessionario.concessionarioAlert_id == concessionario_id));
                        if (newAlert != undefined) {
                            newAlert = Array.from([newAlert]);
                        }

                        ring = compareAlerts(oldAlert, newAlert, state);
                        if (ring) return;
                    }
                })
            }
        }
    });
    tempState = { ...tempState, quote: tempQuote, eventi: tempEventi, alertsBar: tempAlertsBar };
    return tempState;
}


/**
 * Permette di recuperare il colore del concessionario, se non è presente il concessionario allora restituisce il colore bianco
 * @param {number} concessionario_id 
 * @param {dizionario} concessionari 
 * @returns {string} codice colore esadecimale nel formato #XXXXXX
 */
export const getConcessionarioColor = (concessionario_id, concessionari) => {
    let colour = '#FFFFFF';
    concessionari.forEach(concessionario => {
        if (concessionario.concessionario_id === concessionario_id) {
            colour = concessionario.color;
            return
        }
    })
    return colour;
}


/**
 * Permette di recuperare le informazioni necessarie dell'applicazione e di salvarle nello stato locale
 * @param {dizionario} state stato dell'applicazione 
 */
export const salvaStatoLocale = (state) => {
    let impostazioni = {}
    // salvo le impostazioni utili nello stato locale
    // salvo lo stato degli alert di confronto in questo caso solo se è abilitato oppure no
    // costruisco un dizionario che avrà come chiave l'id del concessionario e valore un dizionario contente le impostazioni
    let confronti = {};
    state.alertConfronti.forEach(confronto => {
        confronti[confronto.concessionario_id] = {
            'abilitato': confronto.abilitato,
            'distanza': confronto.distanza,
        }
    });
    impostazioni['alertConfronti'] = confronti;

    // salvataggio stato dei concessionari salverò un dizionario che avrà come chiave 
    // l'id del concessionario e valore un dizionario contente le impostazioni
    let concessionari = {};
    state.concessionari.forEach(concessionario => {
        concessionari[concessionario.concessionario_id] = {
            'visible': concessionario.visible,
            'color': concessionario.color,
        }
    });
    impostazioni['concessionari'] = concessionari;

    // salvo lo stato delle specialità
    let specialita = {};
    state.specialita.forEach(spec => {
        specialita[spec.id] = {
            'visible': spec.visible,
        }
    });
    impostazioni['specialita'] = specialita;

    let campionatiIgnoratiDefault = {};
    state.campionatiIgnoratiDefault.forEach(campionato => {
        campionatiIgnoratiDefault[campionato.id]  = {
            'descrizione': campionato.descrizione
        }
    })
    impostazioni['campionatiIgnoratiDefault'] = campionatiIgnoratiDefault
    localStorage.setItem('state', JSON.stringify(impostazioni));
}

// Salva lo stato dei campionati ignorati
export const salvaStatoLocaleCampionatiIgnorati = (state) =>{
    let impostazioni = getStatoLocale() 
    impostazioni = impostazioni === null ? {} : impostazioni
    let campionatiIgnoratiDefault = {};
    state.campionatiIgnoratiDefault.forEach(campionato => {
        campionatiIgnoratiDefault[campionato.id]  = {
            'descrizione': campionato.descrizione
        }
    })
    impostazioni['campionatiIgnoratiDefault'] = campionatiIgnoratiDefault
    localStorage.setItem('state', JSON.stringify(impostazioni));
}

/**
 * 
 * @returns {dizionario} dizionario contenente le impostazioni salvate nello stato locale potrebbe tornare null se lo stato non è stato salvato
 */
export const getStatoLocale = () => {
    let impostazioni = JSON.parse(localStorage.getItem('state'));
    return impostazioni;
}



/**
 * Permette di ritornare la struttura degli alert di confronto con le impostazioni salvate nello stato locale se presenti altrimenti ritorna quelle standard
 * @param {dizionario} localState stato locale salvato precedentemente
 * @returns 
 */
export const mergeAlertConfrontiWithLocalState = (localState) => {
    // dato che gli alert di confronto possono cambiare dato che sono definiti staticamente non posso inserirli così come sono nello stato
    // quindi devo costruirli
    // recupero quelli statici
    let alertConfronti = LiveConst.ALERTS;
    // una volta recuperati me li scorro e verifico se sono presenti nel localState
    if (localState !== null && localState.alertConfronti !== undefined) {
        alertConfronti.forEach(alert => {
            if (alert.concessionario_id in localState.alertConfronti) {
                alert.abilitato = localState.alertConfronti[alert.concessionario_id].abilitato;
                alert.distanza = localState.alertConfronti[alert.concessionario_id].distanza;
            }
        })
    }
    return alertConfronti;
}


/**
 * Permette di ritornare la struttura dei concessionari con le impostazioni salvate nello stato locale se presenti altrimenti ritorna quelle scaricate con i parametri di default
 * @param {dizionario} localState insieme delle impostazioni salvate nello stato locale
 * @param {dizionario} concessionari insieme dei concessionari scaricati
 * @returns concessionari modificati con le impostazioni salvate nello stato locale
 */
export const mergeConcessionariWithLocalState = (localState, concessionari) => {
    // aggiungo lo stato visible a tutti i concessionari

    concessionari.forEach(element => {
        let color = '#FFFFFF'
        let visible = true

        // setto per BETFAIR il colore di default
        if (parseInt(element.concessionario_id) === LiveConst.BETFAIR_ID) {
            color = "#fac9d1"
        }
        if (parseInt(element.concessionario_id) === LiveConst.BET365_ID) {
            color = "#66ffb2"
        }

        // verifico se il concessionario è presente nella chiave relativa per lo stato locale
        if (localState !== null && localState.concessionari !== undefined && element.concessionario_id in localState.concessionari) {
            visible = localState.concessionari[element.concessionario_id].visible
            color = localState.concessionari[element.concessionario_id].color
        }
        element.visible = visible
        element.color = color


    });
    return concessionari;
}


/**
 * Permette di ritornare la struttura delle specialità con le impostazioni salvate nello stato locale se presenti altrimenti ritorna quelle scaricate con i parametri di default
 * @param {dizionario} localState stato locale salvato precedentemente
 * @param {dizionario} specialita insieme delle specialità scaricate
 * @returns specialità modificati con le impostazioni salvate nello stato locale
 */
export const mergeSpecialitaWithLocalState = (localState, specialita) => {
    // aggiungo lo stato visible a tutte le specialità
    specialita.forEach(element => {
        // verifico se la specialità è presente nella chiave relativa per lo stato locale
        if (localState !== null && localState.specialita !== undefined && element.id in localState.specialita) {
            element.visible = localState.specialita[element.id].visible
        } else {
            element.visible = true
        }
    });
    return specialita;
}

const sound = new Audio(suono_alert);
/**
 * Permette di cparagonare i vecchi alert ai nuovi e di farli suonare se necessario
 * @param {array/false/undefined} oldAlerts array dei vecchi alert (può essere anche o false o undefined)
 * @param {array/false/undefined} newAlerts array dei nuovi alert (può essere anche o false o undefined)
 * @param {dizionario} state stato dell'applicazione
 * @returns 
 */
export const compareAlerts = (oldAlerts, newAlerts, state) => {
    // scorro i nuovi alert e verifico se non fossero già presenti il quelli vecchi
    let alerts = [];
    if (newAlerts) {
        if (oldAlerts) {
            newAlerts.forEach(newAlert => {
                let found = false;
                oldAlerts.forEach(oldAlert => {
                    if (newAlert.info.concessionario_id === oldAlert.info.concessionario_id) {
                        found = true;
                        return
                    }
                });
                if (!found) {
                    alerts.push(newAlert);
                }
            });
        } else {
            alerts = [...newAlerts];
        }
    }

    // verifico per gli alert aggiunti se gli alert sono abilitati
    let ring = false;
    alerts.forEach((alert) => {
        if (confrontoAbilitato(alert.info.concessionario_id, state.alertConfronti)) {
            sound.play();
            ring = true
            return
        }
    })
    return ring
}


/**
 * Ritorno l'elenco degli alert per un relativo "element" che mi rappresenta un aggiornamento di quote, nel caso in cui fosse relativoad un concessionario di confronto allora non avrò
 * un solo array nella variabile di ritorno ma avrò un dizionario che avrà come chiave l'id del concessionario e valore l'array degli alert
 * @param {dizionario} state stato dell'applicazione
 * @param {dizionario} element aggiornamento di quota
 * @param {dizionario} dictQuote dizionario contente tutte le quote
 * @returns 
 */
export const getAlerts = (state, element, dictQuote) => {
    let alerts = {};
    if (!isConcessionarioConfronto(element.concessionario_id, state.alertConfronti)) {
        alerts[element.concessionario_id] = dictQuote[element.pevento_id][element.pquota_id][element.concessionario_id]['alerts'];
    } else {
        Object.keys(dictQuote[element.pevento_id][element.pquota_id]).forEach(concessionario_id => {
            if (concessionario_id !== element.concessionario_id) {
                alerts[concessionario_id] = dictQuote[element.pevento_id][element.pquota_id][concessionario_id]['alerts'];
            }
        })
    }
    return alerts;
}


/**
 * Permette di ritornare una lista delle chiavi del dizionario ordinate per data, campionato, home, away 
 * @param {dizionario} tempEventi struttura dati degli eventi
 * @returns {array} lista delle chiavi ordinate
 */
export const reorderEventi = (tempEventi, eye_state) => {
    // ordino il dizionario degli eventi per data, campionato, home, away
    return Object.keys(tempEventi).sort((a, b) => {

        let dataA = new Date(tempEventi[a].start)
        let dataB = new Date(tempEventi[b].start)
        let campionatoA = tempEventi[a].descrizione
        let campionatoB = tempEventi[b].descrizione
        let homeA = tempEventi[a].home
        let homeB = tempEventi[b].home
        let awayA = tempEventi[a].away
        let awayB = tempEventi[b].away
        let dataAttivazioneAlertA = tempEventi[a].data_attivazione_alert
        let dataAttivazioneAlertB = tempEventi[b].data_attivazione_alert
        if (eye_state) {
            if (dataAttivazioneAlertA && dataAttivazioneAlertB) {
                if (dataAttivazioneAlertA < dataAttivazioneAlertB) {
                    return -1
                }
                if (dataAttivazioneAlertA > dataAttivazioneAlertB) {
                    return 1
                }
            }
        }
        if (dataA < dataB) {
            return -1
        }
        if (dataA > dataB) {
            return 1
        }
        if (campionatoA < campionatoB) {
            return -1
        }
        if (campionatoA > campionatoB) {
            return 1
        }
        if (homeA < homeB) {
            return -1
        }
        if (homeA > homeB) {
            return 1
        }
        if (awayA < awayB) {
            return -1
        }
        if (awayA > awayB) {
            return 1
        }
        return 0
    })
}


/**
 * Ritorna la struttura dei campionati di default ignorati dallo stato locale
 * @param {*} localstate insieme delle impostazioni salvate nello stato locale
 * @returns Array dei campionati ignorati di default
 */
export const getCampionatiIgnoratiDefaultFromLocalState = (localstate) =>
    {
        let campionatiIgnoratiDefault = []
    
        if(localstate !== null && localstate.campionatiIgnoratiDefault !== undefined && Object.keys(localstate.campionatiIgnoratiDefault).length > 0)
        {
            for(const campionato in localstate.campionatiIgnoratiDefault)
            {
                campionatiIgnoratiDefault.push({
                    id: parseInt(campionato),
                    descrizione: localstate.campionatiIgnoratiDefault[campionato].descrizione
                })
            }
        }
    
        return campionatiIgnoratiDefault
    } 
    
    /**
     * Recupera solamente gli ID dei campionati ignorati di default
     * @param {*} campionati Campionati di default
     * @returns Array con solo gli ID dei campionati di default ignorati
     */
    export const getCampionatiIgnoratiDefaultId = (campionati) =>
    {
        let campionatiDefaultId = []
    
        if(campionati != null)
        {
            campionati.forEach((campionato) =>{
                campionatiDefaultId.push(parseInt(campionato.id))
            })
        }
    
        return campionatiDefaultId
    }
