import { checkAlertsQuota, setAlerts, setAlertsEsito, setAlertsEvento } from "../../utils/alert";
import { checkAllEventoFiltri, checkEventoFiltri } from "../../utils/filtri";
import { mergeAlertConfrontiWithLocalState, addKeyToEventi, addKeyToEvento, getStatoLocale, salvaStatoLocale, setQuoteAggiornate, mergeConcessionariWithLocalState, mergeSpecialitaWithLocalState, sortingAlertBar, getCampionatiIgnoratiDefaultFromLocalState , getCampionatiIgnoratiDefaultId, salvaStatoLocaleCampionatiIgnorati  } from "../../utils/utils_func";
import { salvaStatoCampionatiIgnorati } from "./actions";
import { LiveActionsType, alertBarInfoEnum } from "./constants"

const localState = getStatoLocale()

const INIT_STATE = {
  concessionari: [],
  specialita: [],
  eventi: {},
  eventiVisibileCount: 0,
  quote: {},
  token: '',
  inversioni_quote: {}, // struttura indicizzata per pquota_id che indicacon quale quota, eventualmente, dovrebbe essere invertita
  forceRender: false,
  elencoAssociazioniEventi: {}, // elenco che conterrà le associazioni tra l'evento presente in un concessionario e l'evento del palinsesto per poter aggiornare con facilità le informazioni dell'evento
  alertConfronti: mergeAlertConfrontiWithLocalState(localState),
  eventi_ignorati: [],  // elenco degli eventi che sono stati ignorati dall'utente
  eye_state: false, // stato dell'occhio che indica se vedere o meno solo gli eventi che hanno un alert attivo
  filtro_evento: '', // filtro che indica se vedere o meno solo gli eventi che contengono la stringa inserita
  campionati_non_visualizzati: [], // elenco dei campionati presenti nel palinsesto
  alert_sound: true, // indica se riprodurre o meno il suono dell'alert
  psport_id: 1, // id dello sport selezionato di default è 1 (calcio)

  alertsBar: [], // contiene le informazioni degli alert scattati da mostrare sulla barra a destra (le modifiche allo stato avvengono in setQuoteAggiornate)
  sortAlertBarInfo: {
    sorting: alertBarInfoEnum.TIMESTAMP,
    order: alertBarInfoEnum.DECRESCENTE
  }, // contiene le informazioni inerenti al sorting degli alert nella barra

  campionatiIgnoratiDefault: getCampionatiIgnoratiDefaultFromLocalState(localState) // contiene i campionati salvati di default
};


type LiveAction = {
  type: string,
  payload: { actionType?: string, data?: any, error?: string },
};

type State = {
  concessionari?: [] | null,
  specialita?: [] | null,
  eventi?: {} | null,
  quote?: {} | null,
  token?: string | null,
  forceRender?: boolean | null,
  elencoAssociazioniEventi?: {} | null,
  alertsBar?: [] | null,
  sortAlertBarInfo?: {} | null,
  campionatiIgnoratiDefault?: [] | null
};


function getEventiVisibili(eventi) {
  let eventiVisibili = {}
  Object.keys(eventi).forEach(pevento_id => {
      if (eventi[pevento_id].visible)
          eventiVisibili[pevento_id] = eventi[pevento_id]
  });
  return eventiVisibili
}

const Live = (state: State = INIT_STATE, action: LiveAction): any => {
  // definizioni delle variabili usate all'interno dello switch [Sulla destra tutti i case dove sono usate]
  var tempSpecialita = []
  var tempConcessionari = []
  var tempEventi = {}
  var tempEvento = {}
  var pevento_id = 0
  var tempQuote = {}
  var tempAlertConfronti = {}
  var tempEventiIgnorati = []
  var tempEyeState = false
  var tempState = {}
  var tempCampionati = {}
  var tempAssociazioniEventi = {}
  var elencoQuote = []
  var parameters = {}
  var tempToken = ''
  var tempPsportId = 0
  var tempAlertsBar = [];
  var tempSortAlertBarInfo = {};
  var tempCampionatiIgnoratiDefault = []; 

  switch (action.type) {
    case LiveActionsType.SORT_ALERT_BAR_INFO:
      // setting delle informazioni inerenti al sorting degli alert sulla barra a destra 
      // il settaggio delle informazioni separate dal sorting ci permette di richiamare il sort usando lo stato configurato da altri componenti
      tempSortAlertBarInfo = {...state.sortAlertBarInfo}
      tempAlertsBar = state.alertsBar;

      if(action.payload.sorting != tempSortAlertBarInfo.sorting 
      || action.payload.order != tempSortAlertBarInfo.order)
      {
        state.sortAlertBarInfo =  action.payload;
        tempAlertsBar = [...state.alertsBar]
        tempAlertsBar = sortingAlertBar(tempAlertsBar, state);
      }
      
      return {
        ...state,
        alertsBar: tempAlertsBar,
        sortAlertBarInfo: action.payload
      }
    case LiveActionsType.SORT_ALERT_BAR:
      // sorting degli alert in base allo stato globale sortAlertBarInfo
      tempAlertsBar = [...state.alertsBar]
      tempAlertsBar = sortingAlertBar(tempAlertsBar, state);
      return {
        ...state,
        alertsBar: tempAlertsBar
      }
    case LiveActionsType.REMOVE_ALERT_BAR:
      // rimozione tramite id dell'alert presente sulla barra  
      tempAlertsBar = [...state.alertsBar]

      let indexRemove = -1;
      for (let index = 0; index < tempAlertsBar.length; index++) {
        if (tempAlertsBar[index].id == action.payload.id) {
          indexRemove = index;
          break;
        }
      }

      if (indexRemove != -1) {
        tempAlertsBar.splice(indexRemove, 1);
      }

      return {
        ...state,
        alertsBar: tempAlertsBar
      }

    case LiveActionsType.ADD_CONCESSIONARI:
      tempConcessionari = mergeConcessionariWithLocalState(localState, action.payload)
      return {
        ...state,
        concessionari: tempConcessionari,
      }
    case LiveActionsType.SET_CONCESSIONARIO_VISIBLE:
      tempConcessionari = [...state.concessionari]
      tempConcessionari.forEach(element => {
        if (element.concessionario_id === action.payload.concessionario_id) {
          element.visible = action.payload.visible
        }
      });
      return {
        ...state,
        concessionari: tempConcessionari,
      }
    case LiveActionsType.ADD_SPECIALITA:
      tempSpecialita = mergeSpecialitaWithLocalState(localState, action.payload)
      return {
        ...state,
        specialita: tempSpecialita,
      }
    case LiveActionsType.SET_DISTANCE_CONFRONTO:
      tempAlertConfronti = [...state.alertConfronti]
      tempAlertConfronti.forEach(element => {
        if (element.concessionario_id === action.payload.concessionario_id) {
          element.distanza = action.payload.distanza
        }
      });
      return {
        ...state,
        alertConfronti: tempAlertConfronti
      }
    case LiveActionsType.CHANGE_ABILITAZIONE_CONFRONTO:
      tempAlertConfronti = [...state.alertConfronti]
      // ricerco l'elemento da modificare
      tempAlertConfronti.forEach(element => {
        if (element.concessionario_id === action.payload.concessionario_id) {
          element.abilitato = action.payload.abilitato
        }
      });
      return {
        ...state,
        alertConfronti: tempAlertConfronti
      }
    case LiveActionsType.ADD_EVENTI:
      // aggiungo la chiave visible a tutti gli eventi
      tempEventi = addKeyToEventi(action.payload.eventi);
      // aggiungo per ogni evento le sue info live
      Object.keys(tempEventi).forEach(pevento_id => {
        if (pevento_id in action.payload.info_eventi) {
          tempEventi[pevento_id].info_live = action.payload.info_eventi[pevento_id].info_live
          tempEventi[pevento_id].stato = action.payload.info_eventi[pevento_id].stato
        }
      });
      return {
        ...state,
        eventi: tempEventi,
        eventiVisibileCount: Object.keys(getEventiVisibili(tempEventi)).length,
      }
    case LiveActionsType.SET_QUOTE_FROM_SOCKET:
      // action che accetta come payload un array dove ogni elemento è composto da
      // pevento_id, concessionario_id, pquota_id, value
      tempQuote = { ...state.quote }
      tempEventi = { ...state.eventi }
      // aggiorno direttamente tutto lo stato delle quote e degli eventi
      tempState = setQuoteAggiornate(action.payload, state)
      return {
        ...tempState,
        eventiVisibileCount: Object.keys(getEventiVisibili(tempState.eventi)).length,
      }
    case LiveActionsType.CHECK_ALERTS:
      tempQuote = { ...state.quote }
      tempEventi = { ...state.eventi }
      // setto gli alert per tutte le quote presenti nella struttura dati
      setAlerts(tempQuote, state.alertConfronti, tempEventi)
      return {
        ...state,
        quote: tempQuote,
        eventi: tempEventi
      }
    case LiveActionsType.SET_ASSOCIAZIONI_EVENTI:
      // recuperare il nuovo elenco delle associazioni e settare anche le quote
      tempAssociazioniEventi = action.payload.elencoAssociazioniEventi
      tempQuote = { ...state.quote }
      elencoQuote = action.payload.elencoQuote
      tempState = { ...state }
      // verifico se ci sono quote da aggiornare
      if (elencoQuote.length > 0) {
        // verifico quali di queste quote ho nel nuovo elenco delle associazioni e costruisco un nuovo elenco di quote da aggiornare
        let tempElencoQuoteAggiornate = []
        elencoQuote.forEach(quota => {
          let key_associazione = String(quota.evento_codice) + '-' + String(quota.concessionario_id)
          if (key_associazione in tempAssociazioniEventi) {
            tempElencoQuoteAggiornate.push({
              pevento_id: tempAssociazioniEventi[key_associazione]['pevento_id'],
              pquota_id: quota.pquota_id,
              concessionario_id: quota.concessionario_id,
              value: quota.valore,
            })
          }
        });
        // aggiorno le quote
        tempState = setQuoteAggiornate(tempElencoQuoteAggiornate, state)
      }

      return {
        ...tempState,
        elencoAssociazioniEventi: tempAssociazioniEventi
      }
    case LiveActionsType.SET_QUOTE_EVENTO:
      tempQuote = { ...state.quote }
      pevento_id = action.payload.pevento_id
      let jsonQuote = action.payload.quote
      if (!(pevento_id in tempQuote)) {
        tempQuote[pevento_id] = {}
      }
      tempQuote[pevento_id] = jsonQuote
      tempEventi = { ...state.eventi }
      let evento = null
      if (pevento_id in tempEventi) {
        evento = tempEventi[pevento_id]
      }
      setAlertsEvento(tempQuote[pevento_id], state.alertConfronti, evento)
      // verifico i filtri
      tempState = { ...state, quote: tempQuote }
      // recupero l'evento
      if (pevento_id in tempEventi) {
        tempEventi[pevento_id].visible = checkEventoFiltri(pevento_id, tempState)
      }
      return {
        ...tempState,
        eventiVisibileCount: Object.keys(getEventiVisibili(tempEventi)).length,
        eventi: tempEventi,
      }
    case LiveActionsType.ADD_EVENTO:
      // accodo all'elenco degli eventi anche l'evento appena scaricato
      // dato che come risposta ho un evento scritto come
      // {pevento_id : {...dati evento...}}}
      tempEventi = { ...state.eventi }
      Object.keys(action.payload.evento).forEach(pevento_id => {
        tempEvento = addKeyToEvento(action.payload.evento[pevento_id])
        tempEvento.info_live = action.payload.info_live
        tempEvento.stato = action.payload.stato
        // dovendo passare alla funzione per il check dei filtri lo stato aggiornato
        // devo inserire prima l'evento nell'elenco degli eventi
        tempEventi[pevento_id] = tempEvento
        // aggiornare lo stato
        tempState = { ...state, eventi: tempEventi }
        tempEvento.visible = checkEventoFiltri(pevento_id, tempState)
        // risettare l'elenco degli eventi
        tempEventi[pevento_id] = tempEvento
      })


      return {
        ...state,
        eventi: tempEventi,
        eventiVisibileCount: Object.keys(getEventiVisibili(tempEventi)).length,
      }
    case LiveActionsType.SET_EVENTO_DATI:
      tempEventi = { ...state.eventi }
      tempEventi[action.payload.pevento_id].info_live = action.payload.info_live
      tempEventi[action.payload.pevento_id].stato = action.payload.stato
      let stato = { ...state }
      return {
        ...stato,
        eventi: tempEventi,
      }
    case LiveActionsType.DELETE_EVENTO:
      tempEventi = { ...state.eventi }
      delete tempEventi[action.payload]
      return {
        ...state,
        eventi: tempEventi,
        eventiVisibileCount: Object.keys(getEventiVisibili(tempEventi)).length,
      }
    case LiveActionsType.REMOVE_EVENTO:
      tempEventi = { ...state.eventi }
      tempEventiIgnorati = [...state.eventi_ignorati]
      // aggiungo l'evento all'elenco degli eventi ignorati
      tempEventiIgnorati.push(action.payload)
      // rimuovo l'evento dall'elenco degli eventi
      delete tempEventi[action.payload]
      return {
        ...state,
        eventi: tempEventi,
        eventi_ignorati: tempEventiIgnorati,
        eventiVisibileCount: Object.keys(getEventiVisibili(tempEventi)).length,
      }
      case LiveActionsType.SET_EYE_STATE:
        // verifico i filtri
        state = {
          ...state,
          eye_state: action.payload,
        }
        tempEventi = checkAllEventoFiltri(state)
        
        return {
          ...state,
          eye_state: action.payload,
          eventi: tempEventi,
          eventiVisibileCount: Object.keys(getEventiVisibili(tempEventi)).length,
        }
      case LiveActionsType.CHECK_FILTERS:
      // verifico i filtri
      tempEventi = checkAllEventoFiltri(state)
      return {
        ...state,
        eventi: tempEventi
      }
    case LiveActionsType.SET_SPECIALITA_VISIBLE:
      tempSpecialita = [...state.specialita]
      tempSpecialita.forEach(element => {
        if (element.id === action.payload.id) {
          element.visible = action.payload.visible
        }
      });
      return {
        ...state,
        specialita: tempSpecialita,
      }
    case LiveActionsType.SET_FILTRO_EVENTO:
      return {
        ...state,
        filtro_evento: action.payload
      }
    case LiveActionsType.SET_CAMPIONATO_NON_VISIBILE:
      tempCampionati = [...state.campionati_non_visualizzati]
      if (tempCampionati.includes(action.payload)) {
        // se è presente lo rimuovo
        tempCampionati.splice(tempCampionati.indexOf(action.payload), 1)
      } else {
        // altrimenti lo aggiungo
        tempCampionati.push(action.payload)
      }
      state= {
        ...state,
        campionati_non_visualizzati: tempCampionati
      }
      // verifico i filtri
      tempEventi = checkAllEventoFiltri(state)
      return {
        ...state,
        eventi: tempEventi,
        eventiVisibileCount: Object.keys(getEventiVisibili(tempEventi)).length,
      }
    case LiveActionsType.SET_CAMPIONATI_VISIBILITA:
      tempCampionati = [...state.campionati_non_visualizzati]
      // se contenuti in tempCampionati allora non vengono visualizzati
      for (const campionato_id of action.payload.campionati) {
        if(action.payload.visibilita)
        {
          if(tempCampionati.includes(campionato_id))
          {
            tempCampionati.splice(tempCampionati.indexOf(campionato_id), 1)
          }
        }else{
          if(!tempCampionati.includes(campionato_id))
            {
              tempCampionati.push(campionato_id)
            }
        }
      }
      state= {
        ...state,
        campionati_non_visualizzati: tempCampionati
      }
      // verifico i filtri
      tempEventi = checkAllEventoFiltri(state)
      return {
        ...state,
        eventi: tempEventi,
        eventiVisibileCount: Object.keys(getEventiVisibili(tempEventi)).length,
      }
    case LiveActionsType.SET_COLORE_CONCESSIONARIO:
      tempConcessionari = [...state.concessionari]
      tempConcessionari.forEach(element => {
        if (element.concessionario_id === action.payload.concessionario_id) {
          element.color = action.payload.color
        }
      });
      return {
        ...state,
        concessionari: tempConcessionari
      }
    case LiveActionsType.SET_ALERT_SOUND:
      return {
        ...state,
        alert_sound: action.payload
      }
    case LiveActionsType.SALVA_STATO:
      salvaStatoLocale(state)
      return state
    case LiveActionsType.SALVA_STATO_CAMPIONATI_DEFAULT:
      salvaStatoLocaleCampionatiIgnorati(state)
      return state
    case LiveActionsType.SET_PARAMETERS:
      parameters = action.payload
      tempPsportId = state.psport_id
      tempToken = state.token
      if ('token' in parameters) {
        tempToken = parameters.token
      }
      if ('psport_id' in parameters) {
        tempPsportId = parseInt(parameters.psport_id)
      }
      return {
        ...state,
        token: tempToken,
        psport_id: tempPsportId
      }
    case LiveActionsType.SET_INVERSIONI_QUOTE:
      return {
        ...state,
        inversioni_quote: action.payload,
      }
      case LiveActionsType.ADD_CAMPIONATO_INGNORATO_DEFAULT:
      tempCampionatiIgnoratiDefault = [...state.campionatiIgnoratiDefault]
      let campionatiID = getCampionatiIgnoratiDefaultId(tempCampionatiIgnoratiDefault)
      if(!campionatiID.includes(action.payload.id))
      {
        tempCampionatiIgnoratiDefault.push({
          id: action.payload.id,
          descrizione: action.payload.descrizione
        })
      }
    return{
      ...state,
      campionatiIgnoratiDefault: tempCampionatiIgnoratiDefault
    }
    case LiveActionsType.REMOVE_CAMPIONATO_IGNORATO_DEFAULT:
      tempCampionatiIgnoratiDefault = [...state.campionatiIgnoratiDefault]
      for (let index = tempCampionatiIgnoratiDefault.length - 1; index >= 0; index--) {
        if(tempCampionatiIgnoratiDefault[index].id == action.payload.id)
        {
          tempCampionatiIgnoratiDefault.splice(index, 1)
          break
        } 
      }
    return{
      ...state,
      campionatiIgnoratiDefault: tempCampionatiIgnoratiDefault
    }
    default:
      return state
  }
}

export default Live;