export const serverConst = {
    END_POINT_LIVE: 'https://betliveweb.gcsoft.it/php/live',
    END_POINT_GET_SPECIALITA: '/get_specialita.php', 
    END_POINT_GET_CONCESSIONARI:'/get_concessionari.php',
    END_POINT_GET_EVENTI: '/get_eventi_live.php',
    END_POINT_GET_QUOTE_FOR_EVENTO: '/get_quote_for_evento.php',
    END_POINT_ELENCO_ASSOCIAZIONI: '/get_associazioni_eventi.php',
    END_POINT_GET_EVENTO: '/get_evento_live.php',
    END_POINT_GET_INVERSIONI_QUOTE: '/get_inversioni_quote.php',
}

export const LiveConst = {
    // versione del pannello
    VERSIONE: "1.10",

    // Costanti per il dimensionamento della griglia
    ROW_HEADER_HEIGHT: 54,
    COLUMN_HEADER_WIDTH: 45, // indica la dimensione del singolo Esito
    COLUMN_WIDTH: 340, // indica la dimensione in larghezza della prima colonna contenente gli eventi 
    ROW_HEIGHT: 90, // inidica la dimensione standard in altezza delle celle della prima colonna


    ALTEZZA_RIGA_CONCESSIONARIO: 26, // altezza della riga contenente il nome del concessionario
    ALTEZZA_RIGA_EVENTO: 25, // altezza della riga contenente il nome dell'evento

    AGGIORNAMENTO_QUOTE: 'quote',
    AGGIORNAMENTO_EVENTO: 'evento',
    LOAD_EVENTI: 'load_eventi',

    EXPIRE_ULTIMO_AGGIORNAMENTO_ASSOCIAZIONI: 3*60, // secondi

    BETFAIR_ID: 6,
    BET365_ID: 12,

    // ALERT: Elenco degli alert, da verificare all'aggiornamento delle quote
    ALERTS: [
        {
            'concessionario_id': 6,
            'descrizione': 'BETFAIR',
            'abilitato': true,
            'distanza': 0.00,
            'colore': '#dc3545',
            'colore_con_distanza': '#F9EF80', // indica il colore di sfondo della cella in caso in cui la quota superi il valore della quota di confronto aggiungendo la distanza
            'colore_testo_con_distanza': 'red',
            'colore_testo': '#fff',
            'priority': 1,
            'id': 0
        },
        {
            'concessionario_id': 12,
            'descrizione': 'BET365',
            'abilitato': true,
            'distanza': 0.00,
            'colore': '#dc3545',    
            'colore_con_distanza': '#F9EF80', // indica il colore di sfondo della cella in caso in cui la quota superi il valore della quota di confronto aggiungendo la distanza
            'colore_testo_con_distanza': 'red',
            'colore_testo': '#fff',
            'priority': 2,
            'id': 1
        },
    ]
}