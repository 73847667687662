import { LiveConst } from "../../../constants/live";
import * as LiveActions from '../../../redux/live/actions';
import { confirmAlert } from 'react-confirm-alert';
import { TfiCup } from "react-icons/tfi";
import { CiMenuBurger } from "react-icons/ci";
import Dropdown from 'react-bootstrap/Dropdown';


const CellaEventoBasket = (props) => {


    let heightEvento = props.style.height - 1;
    

    return(
        <>
        <style>
        {`
    .dropstart .dropdown-toggle::before {
      display: none;
    }
    `}
        </style>
        {props && props.evento &&
            <div className="col overflow-hidden cellaevento">
                <div className="row">
                    <div className="col-1 p-1 pt-3" style={{backgroundColor: '#999', borderRight: '1px solid gray'}}>
                    <Dropdown drop="start" style={{display: 'flex', justifyContent: 'center', position: 'absolute'}}>
                            <Dropdown.Toggle split style={{padding: "0px", margin: "0px", border: "0px", background: "transparent" }} >
                                <CiMenuBurger size={12} color="black" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{fontSize: '14px'}}>
                                <Dropdown.Item onClick={()=>{props.removeEvento(props.evento.pevento_id)}}>Rimuovi Evento</Dropdown.Item>
                                <Dropdown.Item onClick={()=>{props.removeCampionato(props.evento.pcampionato_id, props.evento.descrizione)}}>Ignora Campionato</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>  
                    </div>
                    <div style={{backgroundColor: 'lightgray', color: 'black'}} className="col-7 text-nowrap overflow-hidden p-0">
                        <div className="col-7 p-1" style={{height:LiveConst.ALTEZZA_RIGA_CONCESSIONARIO}}>{props.evento.home}</div>
                        <div className="col-7 p-1" style={{height:LiveConst.ALTEZZA_RIGA_CONCESSIONARIO}}>{props.evento.away}</div>
                    </div>
                    {props.evento.stato == 'non_iniziato'?
                        <div style={{backgroundColor: 'black'}} className="col-2 p-0 text-center p-1 pt-3">
                                {new Date(props.evento.start).toLocaleTimeString('it-IT', {hour: '2-digit', minute:'2-digit'})}
                        </div>
                        :
                        <div style={{backgroundColor: 'black'}} className="col-2 p-0 text-center p-1">
                            <div className="col-12">
                                {props.evento.info_live.quarto ? 'Q' + props.evento.info_live.quarto :  ''}
                            </div>
                            <div className="col-12 pt-2">
                                {parseInt(props.evento.info_live.minuto) >= 0 && props.evento.stato == 'in_corso'? (parseInt(props.evento.info_live.minuto) == 0)? '<\'1' : '\'' + props.evento.info_live.minuto : 'INT.'}
                            </div>
                        </div>
                    }
                    <div style={{backgroundColor: 'green'}} className="col-2 p-0 text-center">
                            <div className="col-12 p-1" style={{height:LiveConst.ALTEZZA_RIGA_CONCESSIONARIO}}>{props.evento.info_live && props.evento.info_live.home_points}</div>
                            <div className="col-12 p-1" style={{height:LiveConst.ALTEZZA_RIGA_CONCESSIONARIO}}>{props.evento.info_live && props.evento.info_live.away_points}</div>
                    </div>
                </div>
                <div className="row">
                    <div style={{color: 'darkgray'}}  className="col-12 p-1 text-center">
                        {props.evento.descrizione}
                    </div>
                    {/* {props.evento.data_attivazione_alert &&                     
                        <div style={{color: 'darkgray'}}  className="col-12 p-1 text-center">
                            Alert Attivato alle: {props.evento.data_attivazione_alert.toLocaleTimeString('it-IT', {hour: '2-digit', minute:'2-digit', second: '2-digit'})}    
                        </div>

                    } */}

                </div>
            </div>
        }
        </>
    );
}
export default CellaEventoBasket;